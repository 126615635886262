import { delegate } from './utils'

delegate(document, 'click', '.j-app-icon .ft-community', (e) => {
  const target = e.target
  const index = Number(target.dataset['index']) + 1
  const title = target.dataset['title']
  const url = target.dataset['url']
  sa('send', { activity_name: 'click_public_bottom', activity_param: {
    index: 1 + '_' + title + '`' + index + '_\'\'',
    url: url
  } })
})
