const { templateType } = gbCommonInfo

if (templateType == 'goods_detail') {
  function loadImg (src) {
    let img = document.createElement('img')
    img.src = src
    img.onload = () => {
      img = null
    }
  }

  const IS_RETINA = typeof window.devicePixelRatio !== 'undefined' && window.devicePixelRatio > 1
  const USE_SUFFIX = IS_RETINA ? '_thumbnail_900x' : '_thumbnail_600x'
  let gallery
  try {
    gallery = JSON.parse(JSON.stringify(window.goodsDetailv2SsrData?.productIntroData?.goods_imgs || { detail_image: [] }))
  } catch (e) {
    gallery = { detail_image: [] }
  }
  if (gallery.main_image) {
    // 兼容
    if (!gallery.detail_image) gallery.detail_image = []
    gallery.detail_image.unshift(gallery.main_image)
  }
  const moreGallery = window.goodsDetailv2SsrData?.productIntroData?.more_goods_imgs || []
  if (moreGallery.length > 0) {
    for (const item of moreGallery) {
      gallery.detail_image.push({
        thumbnail: item.image_url,
        origin_image: item.image_url
      })
    }
  }
  gallery.detail_image.forEach(_ => {
    loadImg(transformImg(_.thumbnail))
    loadImg(transformImg(dprInsert(_.origin_image)))
  })
  function dprInsert(img = '') {
    const index = img.indexOf('.jpg') !== -1 ? img.indexOf('.jpg') : img.indexOf('.png')
    const akamaiSuffix = img.includes('//img.ltwebstatic.com/images2_pi/') || transformImg(img).includes('//img.shein.com/') ? (IS_RETINA ? '1199' : '799') : ''
    if (index !== -1) {
      return img.slice(0, index) + (USE_SUFFIX + akamaiSuffix) + img.slice(index)
    } else {
      return img
    }
  }
}
