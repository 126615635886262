/**
 * 
 * @param {Event} _e 
 */
// eslint-disable-next-line no-unused-vars
function callback(_e) {}
/**
 * 
 * @param {Element} element 
 * @param {keyof GlobalEventHandlersEventMap} event 
 * @param {string} selector 
 * @param { callback } callback
 */
export function delegate(element, event, selector, callback) {
  element.addEventListener(event, e => {
    const targets = element.querySelectorAll(selector)
    for (const target of targets) {
      if (target === e.target) {
        callback(e)
        break
      }
    }
  })
}
