import mitt from 'mitt'
import { SMessage } from '@shein-aidc/sui-message'
import { getLoginPageLanguage } from '@login/utils/loginPageLanguage'

const { SHEIN_KEY_PC_14541 } = (gbCommonInfo && gbCommonInfo.language) || {}
const eventBus = mitt()
eventBus.opts = {}

// 多实例对象
let Instances = { default: null }
window.Instances = Instances
class InstanceActions {
  queue = [] // 展示实例队列
  cur = '' // 当前展示实例
  opts = {}
  constructor() {}
  getOpts() {
    return this.opts[this.cur] || {}
  }

  setOpts(opts = {}) {
    return (this.opts[this.cur] = opts)
  }
  /**
   *
   * @param {*} name 实例名字
   * @param {*} opts 实例参数
   */
  push(name, opts) {
    if (name != this.cur) this.queue.push(name)
    this.cur = name
    this.actionCb(opts)
    this.setOpts(opts)
  }

  pop() {
    const name = this.queue.pop()
    this.cur = this.queue[this.queue.length - 1]
    if (this.opts[name]) delete this.opts[name]
  }

  callCb(response) {
    const name = this.cur
    const cb = this.opts[name]?.realCb

    this.pop()
    typeof cb == 'function' && cb(response)
  }
  // 回调函数包装,作用通用事件处理
  actionCb(opts) {
    if (typeof opts.cb !== 'function') return
    opts.realCb = opts.cb
    opts.cb = (response) => {
      this.callCb(response)
    }
  }
}

const instanceActions = new InstanceActions()

/**
 * 对外提供的方法,展示弹窗登录组件
 * 注意: 每个新的LOGIN实例的store是单独的一套  !!!!!!
 * @param {object} config
 * @param config.cb 回调函数
 * @param config.closecb 手动关闭回调函数
 * @param config.from 用来区分来源
 * @param config.extra
 * @param config.gextra  ga埋点登录场景区分
 * @param config.bextra  自有埋点登录场景区分
 * @param config.bi  自有埋点登录场景区分
 * @param config.sextra  神策埋点登录场景区分
 * @param config.isShowMainPop 是否展示主登录界面，展示内层弹窗逻辑等
 * @param config.innerPop 内层弹窗逻辑参数
 * @param config.instance  实例标识，拉起多个实例的时候需要传这个标识
 * @param config.action  操作行为
 * @param config.action_val  操作参数
 *
 * 例子:
 *
 * showPop({ show: true, reload: false, to: '', cb: () => {})
 */
let isLoadingPage = false

function showPop(config = {}) {
  // eventBus.opts = config
  const instancename = config.instance || 'default'
  instanceActions.push(instancename, config)
  if (Instances[instancename]) {
    showInstancePop(config, instancename)
    return
  }
  if (isLoadingPage) return
  isLoadingPage = true
  loadLoginComponent(config, instancename)
  SMessage({
    type: 'loading',
    message: `${SHEIN_KEY_PC_14541}...`,
    duration: 0,
  })
}

function showInstancePop(data, instancename) {
  Instances[instancename].showPop(data)
}

function loadLoginComponent(config, instancename) {
  getLoginPageLanguage(() => {
    import(/* webpackChunkName: "signup-module" */ './index').then((v) => {
      Instances[instancename] = v.default()
      SMessage.closeAll()
      isLoadingPage = false
      showInstancePop(config, instancename)
    })
  })
}

window.SHEIN_SIGNUP = {
  instanceActions,
  show: showPop,
  eventBus,
}
