import { abtservice } from 'public/src/services/abt'
import schttp from 'public/src/services/schttp/index'

const abtResult = {}
async function getLogAbtResults () {
  // return true
  if (abtResult.type == 'on') return true
  // eslint-disable-next-line @shein-aidc/abt/abt
  const result = await abtservice.getUserAbtResult({ posKeys: 'CookieResult' })
  const abtInfo = result['CookieResult']
  // eslint-disable-next-line require-atomic-updates
  abtResult.type = abtInfo.param || 'off'
  return abtResult.type == 'on'
}

export async function sendLogs(params) {
  const abt = await getLogAbtResults()
  if (abt)
    schttp({ method: 'post', url: '/api/user/auth/cookies/get', data: params })
}

export function formatLogParams (v) {
  const all = ['C0001', 'C0002', 'C0003', 'C0004', 'SPD_BG']
  const disagree = []
  const agree = []
  all.forEach((k) => {
    if (v.includes(k)) agree.push(k)
    else disagree.push(k)
  })
  return {
    disagree: disagree.join(','),
    agree: agree.join(','),
    scene: agree.length == all.length ? 2 : 1
  }
}
