import schttp from 'public/src/services/schttp'

export function getInitCouponPackageApi(params) {
  return schttp({
    url: '/api/coupon/getCouponPackage/init',
    method: 'POST',
    data: {
      blackbox: window?._fmOpt?.__blackbox || '',
      ...params
    },
    needLogin: false
  })
}
