class StaticPerformanceUpload {
  prefix = 'static_'
  regExps = [
    /^https:\/\/common\.ltwebstatic\.com\/dist_bundle\/.+\/(.+\.js)$/,
  ]
  // 站点维度
  originQueue = {}
  // 文件维度
  resourceQueue = {}
  options = {
    random: 0.1,
    immediate: true
  }
  push(entry) {
    if (typeof entry?.name !== 'string') return
    if (!this.regExps.some(_ => entry.name.match(_))) return

    this._pushOrigin(entry)
    this._pushResource(entry)
  }

  _pushOrigin(entry) {
    const resource = new URL(entry.name).origin
    if (this.originQueue[resource]) return

    const dnst = parseInt(entry.domainLookupEnd - entry.domainLookupStart)
    const tcpt = parseInt(entry.connectEnd - entry.connectStart)
    this.originQueue[resource] = {
      resource,
      data: [
        {
          key_path: this.prefix + 'dnst',
          values: { num: dnst }
        },
        {
          key_path: this.prefix + 'tcpt',
          values: { num: tcpt }
        }
      ]
    }
  }

  _pushResource(entry) {
    const resource = entry.name
    if (this.resourceQueue[resource]) return

    const duration = parseInt(entry.duration)
    this.resourceQueue[resource] = {
      resource,
      data: [
        {
          key_path: this.prefix + 'download_duration',
          values: { num: duration }
        },
      ]
    }
  }

  send() {
    Object.keys(this.originQueue).forEach(_ => window.TPM?.runImmediate({
      marketing: 'ClientAnalytics',
      method: '_defineTrack',
      params: { data: this.originQueue[_], options: this.options }
    }))

    Object.keys(this.resourceQueue).forEach(_ => window.TPM?.runImmediate({
      marketing: 'ClientAnalytics',
      method: '_defineTrack',
      params: { data: this.resourceQueue[_], options: this.options }
    }))
  }
}

window.addEventListener('TPM_SDK_Loaded', () => {
  const staticUpload = new StaticPerformanceUpload()
  // 优先级降低
  performance.getEntriesByType('resource').forEach(_ => {
    staticUpload.push(_)
  })
  staticUpload.send()
})
