/**
 * Created by Denis Radin aka PixelsCommander
 * http://pixelscommander.com
 *
 * Polyfill is build around the principe that janks are most harmful to UX when user is continously interacting with app.
 * So we are basically preventing operation from being executed while user interacts with interface.
 * Currently this implies scrolls, taps, clicks, mouse and touch movements.
 * The condition is pretty simple - if there were no interactions for 300 msec there is a huge chance that we are in idle.
 */

var applyPolyfill = function () {
  //By default we may assume that user stopped interaction if we are idle for 300 miliseconds
  var IDLE_ENOUGH_DELAY = 300
  var timeoutId = null
  var callbacks = []
  var lastInteractionTime = Date.now()
  var deadline = {
    timeRemaining: IDLE_ENOUGH_DELAY
  }

  var isFree = function () {
    return timeoutId === null
  }

  var onContinousInteractionStarts = function () {
    deadline.timeRemaining = 0
    lastInteractionTime = Date.now()

    if (!timeoutId) {
      timeoutId = setTimeout(timeoutCompleted, IDLE_ENOUGH_DELAY)
    }
  }

  var onContinousInteractionEnds = function () {
    clearTimeout(timeoutId)
    timeoutId = null

    for (var i = 0; i < callbacks.length; i++) {
      executeCallback(callbacks[i])
    }
  }

  //Consider categorizing last interaction timestamp in order to add cancelling events like touchend, touchleave, touchcancel, mouseup, mouseout, mouseleave
  document.addEventListener('keydown', onContinousInteractionStarts.bind(this, 'keydown'))
  document.addEventListener('mousedown', onContinousInteractionStarts.bind(this, 'mousedown'))
  document.addEventListener('touchstart', onContinousInteractionStarts.bind(this, 'touchstart'))
  document.addEventListener('touchmove', onContinousInteractionStarts.bind(this, 'touchmove'))
  document.addEventListener('mousemove', onContinousInteractionStarts.bind(this, 'mousemove'))
  document.addEventListener('scroll', onContinousInteractionStarts.bind(this, 'scroll'), true)


  var timeoutCompleted = function () {
    var expectedEndTime = lastInteractionTime + IDLE_ENOUGH_DELAY
    var delta = expectedEndTime - Date.now()

    if (delta > 0) {
      timeoutId = setTimeout(timeoutCompleted, delta)
    } else {
      onContinousInteractionEnds()
    }
  }

  var createCallbackObject = function (callback, timeout) {
    var callbackObject = {
      callback: callback,
      timeoutId: null
    }

    callbackObject.timeoutId = timeout !== null ? setTimeout(executeCallback.bind(this, callbackObject), timeout) : null

    return callbackObject
  }

  var addCallback = function (callbackObject) {
    callbacks.push(callbackObject)
  }

  var executeCallback = function (callbackObject) {
    var callbackIndex = callbacks.indexOf(callbackObject)

    if (callbackIndex !== -1) {
      callbacks.splice(callbacks.indexOf(callbackObject), 1)
    }

    callbackObject.callback(deadline)

    if (callbackObject.timeoutId) {
      clearTimeout(callbackObject.timeoutId)
      callbackObject.timeoutId = null
    }
  }

  return function (callback, options) {
    var timeout = (options && options.timeout) || 0
    var callbackObject = createCallbackObject(callback, timeout)

    if (isFree()) {
      executeCallback(callbackObject)
    } else {
      addCallback(callbackObject)
    }
  }
}

if (!window.requestIdleCallback) {
  window.ricActivated = true
  window.requestIdleCallback = applyPolyfill()
}

window.requestUserIdle = window.ricActivated && window.requestIdleCallback || applyPolyfill()
