import { v4 as uuidv4 } from 'uuid'
import { parseQueryString } from '@shein/common-function'
const { REPORT_URL } = gbCommonInfo
const { MARKETING_URL } = REPORT_URL

// 营销归因埋点发送:用户着陆
const sendMdmpUserLanding = () => {
  try {
    const {
      url_from = '',
      aff_id = '',
      onelink = '',
      scene = '',
      campaign = '',
      journey_name = '',
      deeplink = '',
      requestId = '',
    } = parseQueryString(location.search)
    if (url_from || aff_id || onelink || scene === 'onelink') {
      const reportUrl = MARKETING_URL + '/mdmpUserLanding'
      const commonParams = {
        uuid: uuidv4(),
        onelink: onelink || journey_name,
        reffer: location.href,
        campaign,
        journey_name,
        scene,
        deeplink,
        channel: url_from,
      }
      window.sa('error', reportUrl, {
        activity_name: 'Landing',
        requestId,
        ...commonParams
      })
      window.sa('send', {
        activity_name: 'click_marketing_url_landing',
        activity_param: {
          referer: encodeURIComponent(window.location.href),
          traffic_source: document.referrer || '',
          bu_department: 'marketing',
          ...commonParams
        }
      })
    }
    if (onelink && gbCommonInfo.pageType === 'oops') {
      window.sa('error', `${MARKETING_URL}/mdmpMarketingWebReport`, {
        activity_name: 'click_onelink_web_error',
        activity_param: {
          bu_department: 'marketing',
        },
        bu_department: 'marketing',
        onelink: onelink || journey_name,
      })
    }
  } catch (e) {
    console.log(e)
  }
}

sendMdmpUserLanding()
