import { hasNode } from 'public/src/pages/common/dom-utils'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
//机器人页面不请求不曝光
if (window.location.href.indexOf('/robot') === -1) {
  // 所需ABT已在公共配置中统一获取 [CccxPublicBanner,CCCxTopbanner]
  getUserAbtData().then(() => {
    if (hasNode('.j-config-top-banners')) {
      import(/* webpackChunkName: "PublicBannerContainer" */ './loadPublicBanner').then(({ default: PublicBannerContainer }) => {
        new PublicBannerContainer().init()
      })
    }
  })
}
