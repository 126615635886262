import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-40' })


const HOME_DIALOG_QUEUE_REPORT = gbCommonInfo && gbCommonInfo.HOME_DIALOG_QUEUE_REPORT
const isReportApolloFlag = (!Number.isNaN(HOME_DIALOG_QUEUE_REPORT) && Number(HOME_DIALOG_QUEUE_REPORT)) || 0
const SupportReportFn = (limit) => {
  const sessionFlag = window.sessionStorage.getItem('_HOME_DIALOG_QUEUE_REPORT')
  if(sessionFlag === 'true' || sessionFlag === 'false') {
    return JSON.parse(sessionFlag)
  }

  // 随机数用于是否上报全局队列埋点场景放量
  const randomNum = Math.floor(Math.random() * 100)
  
  const newFlag = randomNum >= 0 && randomNum <= Number(limit)
  window.sessionStorage.setItem(
    '_HOME_DIALOG_QUEUE_REPORT',
    newFlag
  )
  return newFlag
}
const SupportReport = SupportReportFn(isReportApolloFlag)
export const POP_UP_MONITOR = {
  // 常规监控
  normaMonitor(params = {}) {
    if(params.nonQueue) {
      // 非队列埋点
      expose_POP_UP_every_monitor(params)
    } else {
      // 队列埋点
      expose_POP_UP_monitor(0, params)
    }
  },
  // 异常监控
  errorMonitor(params = {}) {
    expose_POP_UP_monitor(1, params)
  },
  // 业务逻辑监控
  operationMonitor(params = {}) {
    expose_POP_UP_monitor(2, params)
  },
}

// 监控事件
// 队列埋点（会根据abt来看是否上报）
export const expose_POP_UP_monitor = (monitor_type, params = {}) => {
  if(!SupportReport)  { // 不支持埋点就不上报埋点
    return
  }
  const { code, failure_reason, event_name = '-', dialog_name, dialog_queue = '-', remark = '-', timeout_dialog = '' } = params
  delete params.code
  const reason = typeof failure_reason === 'string' ? failure_reason : JSON.stringify(failure_reason)
  
  let extraData = {
    monitor_type,
    code_branch: code,
    failure_reason: reason || '-',
    event_name,
    dialog_name,
    dialog_queue,
    remark
  }
  timeout_dialog && (extraData.timeout_dialog = timeout_dialog)
  daEventCenter.triggerNotice({
    daId: '2-40-2',
    extraData,
  })
}

// 非队列埋点
export const expose_POP_UP_every_monitor = (params = {}) => {
  daEventCenter.triggerNotice({
    daId: '2-40-1',
    extraData: params
  })
}
