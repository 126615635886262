import schttp from 'public/src/services/schttp'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'
import { checkRedpointExpireTime, setRedpointExpiredTime, getRedpointStatus, setRedpointStatus } from '@shein-aidc/bs-sdk-wish-list-red-point-status'

// 红点回调队列，红点 API 请求完毕后会遍历执行此队列
let redpointFnQueue = []

// 红点请求对象
let redpointReq = null
const useBffApi = isSwitchBffApiVersion(['wishlistGroup_v3']) || {}

// 红点回调
let callBackRed = null

export let repPointState = {
  redpoint: 0, // 红点状态，0=不显示，1=降价红点，2=低库存红点
  hidePopoverAnimation: false //是否隐藏红点气泡
}

/**
 * 检查本地缓存红点间隔请求过期时间
 */
function checkRedpointStoreExpire() {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'wish/redpoint' })
  return checkRedpointExpireTime(memberId)
}

/**
 * 设置本地暂存红点间隔请求过期时间
 */
function setRedpointStoreExpired() {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'wish/redpoint' })
  setRedpointExpiredTime(memberId)
}

/**
 * 设置红点状态
 */
function setRedpointState({ redpoint = 0, hidePopoverAnimation = false } = {}) {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'wish/redpoint' })
  setRedpointStatus({
    memberId: memberId,
    redpointStatus: redpoint,
  })
  repPointState.redpoint = redpoint
  repPointState.hidePopoverAnimation = !!hidePopoverAnimation
}

// 执行红点回调
export function redpointExecute() {
  callBackRed && callBackRed()
}

// 收藏商品页对InStock标签云有些特殊处理，需要单独记录
// state: 0初始化WishlistInStock=C时默认选中  1选中 2未选中 redpoint：0未点击未显示气泡 1 未点击已显示红点 2已点击不显示红点
export const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'wish/instock' })
export const InStockStorageRedpointKey = `wishlistInStock-${memberId}`
export const getInStockStorageRedpoint = () => {
  const wishlistInStock = JSON.parse(localStorage.getItem(InStockStorageRedpointKey)) || {
    state: 0,
    redpoint: 0
  }

  return wishlistInStock
}

/**
 * 检查红点状态
 * @param {Function} fn 回调函数
 * @param {Boolean} flag 是否不执行（通过外部调用执行）
 */
export function checkRedpoint(fn, flag, options = {}) {
  callBackRed = async () => {
    const isLogin = isLoginFn() ? true : false
    const isExpired = checkRedpointStoreExpire()

    redpointFnQueue.push(fn)
    
    // 未登录隐藏红点
    if (!isLogin) {
      setRedpointState({ redpoint: 0 })
      redpointFnQueue.forEach((f) => f())
      redpointFnQueue = []
      return
    }

    // InStock标签云优先级最高
    const InStockStorage = getInStockStorageRedpoint()
    if (InStockStorage.redpoint !== 2 && options?.WishlistInStock === 'B') {
      setRedpointState({ redpoint: 4, hidePopoverAnimation: true })

      localStorage.setItem(InStockStorageRedpointKey, JSON.stringify({
        state: 2,
        redpoint: 1
      }))

      redpointFnQueue.forEach((f) => f())
      redpointFnQueue = []
    } else {
      // 当前位于频次控制范围内（被动消失后 24 小时内不显示红点）
      if (!isExpired) {
        setRedpointState({ redpoint: 0 })
        redpointFnQueue.forEach((f) => f())
        redpointFnQueue = []
        return
      }
      if (gbCommonInfo.IS_NEW_HEAD) {
        // 业务组件收藏红点的显隐和埋点在业务组件处理了
        const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'wish/redpoint' })
        const redpoint = getRedpointStatus(memberId)
        setRedpointState({ redpoint })
        return 
      }

      if (redpointReq) {
        return
      }
  
      // 请求 API 获取红点状态
      const params = useBffApi?.['wishlistGroup_v3'] ? { 
        url: '/user/get_wishlist_red_dot',
        useBffApi: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : { url: '/api/user/wishlist/redpoint/get' }

      redpointReq = schttp(params).then(({ code, info }) => {
        redpointReq = null
  
        if (Number(code) !== 0 || !info.result) {
          setRedpointState({ redpoint: 0 })
          redpointFnQueue.forEach((f) => f())
          redpointFnQueue = []
          return
        }

        const hidePopoverAnimation = options?.WishlistInStock === 'C' && InStockStorage.redpoint !== 2
  
        setRedpointState({ redpoint: Number(info.result), hidePopoverAnimation })
        redpointFnQueue.forEach((f) => f())
        redpointFnQueue = []
      })

    }
  }
  !flag && callBackRed()
}

/**
 * 隐藏红点
 */
export function hideRedpoint() {
  const isExpired = checkRedpointStoreExpire()

  if (!isExpired && repPointState.redpoint === 0) {
    return
  }

  setRedpointState({ redpoint: 0 })
  setRedpointStoreExpired()

  // 隐藏头部收藏入口红点
  document.querySelectorAll('.header-wishlist .header-nopenum').forEach(function(element){
    element.classList.add('she-hide')
  })
}
