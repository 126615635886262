import schttp from 'public/src/services/schttp/index'

export function getLoginPageLanguage(cb, isRefresh = false ) {
  if (
    typeof window.LoginPageLangText !== 'undefined' &&
      Object.keys(window.LoginPageLangText).length && !isRefresh
  ) {
    cb && cb({ language: window.LoginPageLangText })
    return
  }
 
  schttp({ url: '/api/auth/initData/get', method: 'POST' })
    .then((res) => {
      const { language, aesKey, LoginPageAbt, LoginPreloadData } = res
      window.LoginPageLangText = language
      window.LoginPageAesKey = aesKey
      if (window?.SHEIN_LOGIN) window.SHEIN_LOGIN.__pre_abt = LoginPageAbt
      if (window?.SHEIN_LOGIN) window.SHEIN_LOGIN.__pre_data = LoginPreloadData
      cb && cb({ language })
    })
    .catch(() => {
      schttp({ url: '/api/common/language/get', params: { page: 'login' } }).then((res) => {
        const { language = {} } = res
        window.LoginPageLangText = language
        cb && cb({ language })
        return
      })
    })
}
