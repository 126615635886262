// 声明式埋点通用解决方案
// 适用场景 同步点击、异步点击
import saModule from './sa'
let uuid = 0

const ASYNC_CLICK_EVENT = 'asyncClick'
const SYNC_CLICK_EVENT = 'syncClick'
const ADD_BAG_EVENT = 'addBag'

export default class DeclarativeAnalysis {
    // 命名空间
    NAMESPACE = 'DA-'
    viewedList = []
    productList = []
    waitViewList = []
    init() {
      setTimeout(() => {
        this.bindEvent()
      }, 3000)
      document.body.addEventListener('click', e => {
        let target = e.target.closest(`[${this.NAMESPACE}type]`)

        if (target) {
          // 发送渠道
          let triggerMode = this.getTrack(target)
          
          let type = target.getAttribute(this.NAMESPACE + 'type')
          if (type == ASYNC_CLICK_EVENT) {
            if (!target.getAttribute(this.NAMESPACE + 'id')) {
              this.createMutationObserver(target)
            }
          }
          if (type == SYNC_CLICK_EVENT) {
            if (triggerMode.sa) {
              saModule.click(this.getSaAttrs(target, ['name', 'param', 'beacon']))
            }
          }
          if (type == ADD_BAG_EVENT) {
            if (!target.getAttribute(this.NAMESPACE + 'id')) {
              this.createMutationObserver(target)
            }
          }
        }
      })
      window.addEventListener('scroll', this.debounce(()=>{
        this.readyScroll()
        this.scrollFunc()
      }, 200))
    }

    /**
     * 
     * @param {Element} target 
     * @returns 
     */
    getTrack(target){
      // 发送渠道
      let triggerMode = {}
      if(target.getAttribute(this.NAMESPACE + 'action')) {
        if (window.GA_REMOVE_TIP) {
          window.GA_REMOVE_TIP('DA-[action|category|label|value]', target)
        }
      }
      if(target.getAttribute(this.NAMESPACE + 'sa-name')) {
        triggerMode.sa = true
      }
      return triggerMode
    }

    bindEvent() {
      // 初始化指令声明
      document.querySelectorAll(`[${this.NAMESPACE}type="${ASYNC_CLICK_EVENT}"]`).forEach((item) => {
        this.createMutationObserver(item)
      })
    }
    // 同步点击发送宿主元素
    syncClickEventCtx = {}
    // 异步点击发送宿主元素
    asyncClickEventCtx = {}
    /**
     * 
     * @param {Element} targetNode 
     * @returns 
     */
    createMutationObserver(targetNode) {
      const config = {
        attributes: true
      }
      targetNode.getAttribute()
      const mutationCallback = mutationsList => {
        for (let mutation of mutationsList) {
          let type = mutation.type
          switch (type) {
            case 'childList':
              break
            case 'attributes':
              if (mutation.attributeName == this.NAMESPACE.toLowerCase() + 'value' && targetNode.getAttribute(this.NAMESPACE.toLowerCase() + 'value') !== '') {
                // 发送渠道
                let triggerMode = this.getTrack(targetNode)
                if (triggerMode.sa) {
                  let attrs = this.getSaAttrs(targetNode, ['name', 'param', 'beacon'])
                  saModule.click(attrs)
                }
              }
              break
            case 'subtree':
              break
            default:
              break
          }
        }
      }
      let observer = new MutationObserver(mutationCallback)
      targetNode.setAttribute(this.NAMESPACE + 'id', uuid++)
      observer.observe(targetNode, config)
      return observer
    }
    // 获取 sa 发送的属性
    /**
     * 
     * @param {Element} target 
     * @param {string[]} attrs 
     * @returns 
     */
    getSaAttrs(target, attrs) {
      return attrs.reduce((curr, attr) => {
        let attrName = this.NAMESPACE + 'sa-' + attr
        let attrValue = target.getAttribute(attrName)
        if (attrValue) {
          curr[attr] = attrValue
        }
        return curr
      }, {})
    }
    debounce(fn, timer) {   
      var timeout
      return function() {
        var ctx = this
        var args = arguments
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(function() {
          fn.call(ctx, args)
        }, timer || 200)
      }
    }
    readyScroll() {
      let elements = document.querySelectorAll(`[${this.NAMESPACE}view-type]`)
      let viewEle = this.filterElement(elements)
      viewEle.forEach((element) => {
        if (element) {
          let modulType = element.getAttribute(`${this.NAMESPACE}view-type`)
          switch(modulType) { 
            case 'other': //普通曝光
              this.otherView(element)
          }
        }
      })
    }
    /**
     * 
     * @param {Element[]} elements 
     * @returns {Element[]}
     */
    filterElement(elements) {
      let top = window.scrollY,
          left = window.scrollX,
          right = left + window.innerWidth,
          bottom = top + window.innerHeight
      let viewEles = []

      elements.forEach(element => {
        const rect = element.getBoundingClientRect()
        var elTop = rect.top + window.scrollY,
            elLeft = rect.left + window.scrollX,
            elRight = elLeft + rect.width,
            elBottom = elTop + rect.height

        var isIntersect = !(elLeft > right ||
				elRight < left ||
				elTop > bottom ||
				elBottom < top)

        if (isIntersect) {
          viewEles.push(element)
        }
      })
      return viewEles
    }
    /**
     * 
     * @param {Element} element 
     */
    otherView(element) {
      if (element.getAttribute(this.NAMESPACE + 'sa-name')) {
        saModule.view(this.getSaAttrs(element, ['name', 'param']))
      }
      element.removeAttribute(`${this.NAMESPACE}view-type`)
    }
    scrollFunc() { //普通曝光埋点
      const documentOffsetHeight = document.body.offsetHeight
      document.querySelectorAll(`[${this.NAMESPACE}view-type]`).forEach((element) => {
        if (element.getAttribute(`${this.NAMESPACE}view-type`) == 'ordinary' && element.getBoundingClientRect().top + 15 <= documentOffsetHeight) {
          this.ordinaryView(element)
        }
      })
    }
    /**
     * 
     * @param {Element} element 
     */
    ordinaryView (element) {
      const triggerMode = []
      let viewNoParam = false // 曝光不需要参数
      if (element.getAttribute(this.NAMESPACE + 'sa-name')) {
        triggerMode.push('sa')
        viewNoParam = element.getAttribute(this.NAMESPACE + 'view-no-param')
      }
      if (triggerMode.includes('sa')) {
        saModule.view(this.getSaAttrs(element, viewNoParam ? ['name'] : ['name', 'param']))
      }
      element.removeAttribute(`${this.NAMESPACE}view-type`)
      element.setAttribute('el-viewed', true)
    }
}
