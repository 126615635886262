/* global SMSdk */
// import { abtservice } from '../../services/abt/index'
import { asyncLoadFile } from '@shein/common-function'
const { PUBLIC_CDN, NODE_SERVER_ENV } = gbCommonInfo
const { generateConsole } = require('../../../../common/utils')

class DeviceFingerPrint {

  abtResult = {} // abt 结果

  isSdkJsLoading = false
  isSdkJsLoaded = false

  isSdkIniting = false
  isSdkInited = false

  isRetrying = false
  isRetryed = false

  isInitXhred = false

  smDeviceId = ''

  callbackStash = [] // 回调队列
  timeoutID = null

  constructor() {
    this.getAbtResult()
  }

  getAbtResult() {
    // abtservice.getUserAbtResult({ posKeys: 'SMDeviceId' }).then((abtInfo) => {
    // this.abtResult = abtInfo && abtInfo['SMDeviceId'] || {}
    // const param = abtInfo && abtInfo['SMDeviceId'] && abtInfo['SMDeviceId'].param
    // if (param != 'stop') {
    this.initDeviceConfig()
    // } else {
    // this.callReTryCallabck()
    // }
    // })
  }
  initDeviceConfig() {
    this.sdkBeforeLoad()
    window._smReadyFuncs = []
    window.SMSdk = { ready: (fn) => fn && window._smReadyFuncs.push(fn) }
    window._smConf = {
      organization: 'FPNyuLhAtVnAeldjikus',
      isOpenUserBehavior: false,
      apiHost: `${location.host}/devices`,
    }
    this.isSdkJsLoading = true
    asyncLoadFile({
      label: 'script',
      attrs: {
        async: true,
        src: `${PUBLIC_CDN}/she_dist/libs/devices/fpv2.5.js`,
      },
    }).then(() => {
      this.isSdkJsLoading = false
      this.isSdkJsLoaded = true
      this.sdkStartInit()
    }).catch(() => {
      this.isSdkJsLoading = false
      this.callReTryCallabck()
    })
  }

  sdkStartInit() {
    this.isSdkIniting = true
    if (window.SMSdk && window.SMSdk.ready) {
      SMSdk.ready(() => {
        this.isSdkIniting = false
        this.isSdkInited = true
        try {
          const SmDeviceId = SMSdk.getDeviceId && SMSdk.getDeviceId() || ''
          this.isSdkReady = true
          this.isSdkIniting = false
          this.smDeviceId = SmDeviceId
          window.__SmDeviceId = SmDeviceId
          window.sessionStorage && window.sessionStorage.setItem('SmDeviceIdAbt', true)
          window.sessionStorage && window.sessionStorage.setItem('SmDeviceId', SmDeviceId)
          // SmDeviceId && this.listenXhrBefore(SmDeviceId)
          this.sdkAfterLoad(SmDeviceId)
          this.callReTryCallabck()
        } catch (e) {
          this.callReTryCallabck()
        }
      })
    }
  }

  sdkBeforeLoad() {
    // 使用本地临时数据
    const SmDeviceId = window.localStorage && window.localStorage.getItem('SmDeviceId') || ''
    window.__SmDeviceId = SmDeviceId
    this.smDeviceId = SmDeviceId
    window.sessionStorage && window.sessionStorage.setItem('SmDeviceIdAbt', true)
    window.sessionStorage && window.sessionStorage.setItem('SmDeviceId', SmDeviceId)
    this.listenXhrBefore()
    if (NODE_SERVER_ENV !== 'production') {
      if (SmDeviceId) {
        generateConsole('SmDeviceId Cache Loading Successfully', SmDeviceId)
      } else {
        generateConsole('SmDeviceId Cache Loading Failed')
      }
    }

  }

  sdkAfterLoad(SmDeviceId) {
    // 替换临时数据
    window.localStorage && window.localStorage.setItem('SmDeviceId', SmDeviceId)
    if (NODE_SERVER_ENV !== 'production') {
      generateConsole('SmDeviceId SDK Loading Successfully', SmDeviceId)
    }
  }

  listenXhrBefore() {
    const self = this
    if (this.isInitXhred) return
    this.isInitXhred = true
    const origiOpen = XMLHttpRequest.prototype.open
    XMLHttpRequest.prototype.open = function () {
      let args = arguments
      origiOpen.apply(this, args)
      if (self.isInSite(args)) {
        const id = self.smDeviceId.split(',')[0]
        this.setRequestHeader('SmDeviceId', id || '')
      }
    }
  }

  isInSite(args) {
    const url = args && args[1] || ''
    // if (/^http(s)?:\/\/www\.srmdata.com/.test(url)) {
    //   return true
    // }
    if (!/^http(s)?:\/\//.test(url)) { // 相对路径
      return true
    }
    const regExp = new RegExp(`^http(s)?://${location.host}`)
    if (regExp.test(url)) { // 本站地址
      return true
    }
    return false
  }

  callReTryCallabck() {
    if (this.isRetrying) {
      clearTimeout(this.timeoutID)
      this.isReTried = true
      this.isRetrying = false
      this.callbackStash.forEach((func) => {
        if (typeof func === 'function') {
          func()
        }
      })
      this.callbackStash = []
    }
  }

  retryInit(cb) {
    if (this.isReTried) {
      return typeof cb === 'function' && cb()
    }
    this.callbackStash.push(cb)
    // 正在二次尝试中
    if (this.isRetrying) {
      return
    }

    this.isRetrying = true
    // 设置超时时间
    this.timeout()
    // abt 服务阶段尝试
    if (!this.abtResult.param) {
      this.getAbtResult()
      return
    }
    // sdkjs 尝试重新加载
    if (!this.isSdkJsLoaded) {
      return this.initDeviceConfig()
    }
    // sdkjs 正在加载中
    if (this.isSdkJsLoading) {
      return
    }

    // sdk 重新初始化
    if (!this.isSdkInited) {
      this.sdkStartInit()
      return
    }
    // sdk 正在初始化中
    if (this.isSdkIniting) {
      return
    }
    this.callReTryCallabck()
  }

  callFunc(cb) {
    if (this.smDeviceId == '') {
      this.retryInit(cb)
      return
    }
    typeof cb == 'function' && cb()
  }

  callFuncPromise() {
    return new Promise((resolve) => {
      this.callFunc((data) => resolve(data))
    })
  }

  timeout() {
    this.timeoutID = setTimeout(() => {
      this.callReTryCallabck()
    }, 5000)
  }
}

window._GB_DeviceFingerPrint = new DeviceFingerPrint()
