import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

async function getCountryCode() {

  let countryData = UserInfoManager.getSync({ key: 'Country', actionType: 'getCountryCodeGetSync' })

  if (!countryData?.country) {
    countryData = await UserInfoManager.get({ key: 'Country', actionType: 'getCountryCodeGet' })
  
    const country = countryData?.countryAbbr || ''
    SHEIN_LOGIN?.eventBus?.emit('default-country-change', country)
  }

}

document.addEventListener('DOMContentLoaded', function () {
  getCountryCode()
}, false)
