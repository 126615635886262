import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import { v4 as uuidv4 } from 'uuid'

export function getBindCouponPackageParams(
  couponId,
  couponList = [],
  abtCouponScene
) {
  if (couponId.split(',').length === 1 && abtCouponScene !== 'B') {
    return {
      couponId,
      coupon_codes: couponList.map(m => m.couponCode || m.coupon)
    }
  }

  const couponPackageGroup = groupBy(couponList, m => m.couponPackageId)
  const coupon_package_info_list = map(couponPackageGroup, (v, k) => {
    return {
      coupon_pack_id: k,
      coupon_codes: v.map(m => m.couponCode || m.coupon)
    }
  })

  return {
    coupon_package_info_list: coupon_package_info_list.length
      ? coupon_package_info_list
      : undefined,
    idempotent_code: uuidv4()
  }
}

/**
 * 转换 promise 返回结果为错误优先
 * @param {Promise} promise
 * @returns {Promise<[Error, any]>}
 */
export const tryit = function (promise) {
  return promise.then(
    value => [undefined, value],
    err => {
      return [err, undefined]
    }
  )
}
