/*globals*/
/**
 * 整合全局弹窗的出现顺序, 在关闭上一个弹窗之后下一个弹窗才会出现
 * ip跳转弹窗 > 隐私政策弹窗 > 券包 > 回访卡券 > 快速注册弹窗
 * */
import mitt from 'mitt'
import './ip_jump'
import { isOnlyIE } from '../components/filter/common/utils'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { getCookie, getLocalStorage } from '@shein/common-function'
import { COUPON_MONITOR } from 'public/src/pages/common/coupon_package/analysis/index.js'
import { POP_UP_MONITOR } from './glob_popup_analysis.js'
import { filterCouponWithin24Hours }  from 'public/src/pages/product_list_v2/components/coupon/utils.js'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'

import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import Monitor from 'public/src/pages/common/home_common/monitor.js'

const { SiteUID, lang, IS_RW } = gbCommonInfo
const isLogin = isLoginFn() // 用户是否登陆

window._GB_PopUpModalEventCenter_ = mitt()

let lastPopupModalName = ''
const MARKETING = 'marketing'
const SHEIN_PUSH = 'sheinPush'
let isSheinPushDone = false
const globPopupModalSort = ['forceUpdatePwd', 'onetrust', MARKETING, 'ip', 'mutiplePartPrivacy', 'mutipleLang',  'coupon', 'couponRevisit', 'quickRegister', 'switchLanguageGuide', 'stopSupportIE'] // 全局弹窗顺序,从左到右

// 全局弹窗队列的name集合
let globDialogQueueNames = []
let startGlobPopupTime = 0 // 初始时间
let ENABLED_TIMEOUT_FLAG = 0 // 是否启动了超时机制 0:正常启动 1:启动了超时机制
let NON_INIT_POPUP_STR = '' // 未初始化完成的弹窗名称 
let EVERY_POPUP_TIME = {} // 记录每个弹窗的初始化时间
// 默认为false，在需要载入相关的弹窗入口的时候初始化为true
const globPopupModalInfo = globPopupModalSort.reduce((val, key) => {
  val[key] = { loading: true, isWait: false }
  return val
}, {})
window._GB_PopUpModalEventCenter_.globPopupModalInfo = globPopupModalInfo

// 处理一些特殊情况的条件
function dealSpecialCondition() {
  const info = globPopupModalInfo
  // 如果同时存在隐私政策弹窗和ip弹窗，只显示ip弹窗
  if (info['ip'] && info['ip'].isWait && info['privacy'] && info['privacy'].isWait) {
    info['privacy'].isWait = false
  }
}

// 劵包确保704事件只触发一次
let onceMonitor = true
// load_time埋点只触发一次
let onceLoadTime = true
// 在相关modal状态初始化回来之后添加入待显示队列
window._GB_PopUpModalEventCenter_.on('global-pop-modal-queue', (data) => {
  const { name, isWait = false } = data

  if (name === 'coupon') {
    COUPON_MONITOR.normaMonitor({
      code: '703',
      show_judge: 1,
      remark: '首页队列接收成功'
    })
  }

  globPopupModalInfo[name] = { loading: false, isWait }
  // 增加每个初始化完成的弹窗的从初始到完成的时间
  EVERY_POPUP_TIME[name] = new Date().getTime() - startGlobPopupTime
  // 如果所有的弹窗请求已完成初始化状态
  if (globPopupModalSort.every((k) => !globPopupModalInfo[k].loading)) {
    dealSpecialCondition()
    if(onceLoadTime) {
      onceLoadTime = false
      const endGlobPopupTime = new Date().getTime()
      const every_popup_time = Object.entries(EVERY_POPUP_TIME || {}).map(([key, value]) => `${key || ''}=${value || ''}`).join('|')
      POP_UP_MONITOR.normaMonitor({
        nonQueue: true, // 非队列埋点，正常上报，不依据apollo来控制上报不上报
        load_time: endGlobPopupTime - startGlobPopupTime,
        every_popup_time,
        is_timeout: ENABLED_TIMEOUT_FLAG,
        remark: '弹窗队列启动所需时间（初始化完成）',
        event_name: 'expose_dq_loadtime'
      })
    }
    window._GB_PopUpModalEventCenter_.next('')

    if (globPopupModalSort.includes('coupon') && onceMonitor) {
      onceMonitor = false
      COUPON_MONITOR.normaMonitor({
        code: '704',
        show_judge: 1,
        remark: '首页队列初始化完成后是否存在券包'
      })
    }
  }
})

/**
 * 加入全局弹窗队列
 * 提供给全局模块的加入队列方法,并且创建一个监听事件提供回调
 * @param name 弹窗模块文字
 * @param cb 回调函数
 */
window._GB_PopUpModalEventCenter_.pushQueue = (name, cb) => {
  if(name === SHEIN_PUSH) {
    if(isSheinPushDone) return
    // shein push由于加载非常慢, 初始化时默认已加载, 此时如果加入空的弹窗队列, 需要弹出
    if(lastPopupModalName === null) {
      lastPopupModalName = ''
    }
  }
  Monitor.metricGlobPopup({
    tags: {
      track_type: '2', // dq_dialog_add
      alert_type: name,
    },
    message: `${name} dq_dialog_add`
  })
  if(!globDialogQueueNames?.includes(name)) {
    globDialogQueueNames.push(name)
  }
  // 弹窗加入队列的埋点逻辑
  POP_UP_MONITOR.operationMonitor({
    event_name: 'expose_dq_dialog_add',
    dialog_name: name,
    dialog_queue: globDialogQueueNames.join(','),
    remark: `${name} 添加到队列中`
  })
  window._GB_PopUpModalEventCenter_.on('global-pop-modal-show', (evtName) => {
    if (name === evtName && typeof cb === 'function') {
      cb()
    } else {
      if (name === evtName && name === 'coupon') { // 上报时，明确是券包弹窗回调函数触发
        COUPON_MONITOR.errorMonitor({
          code: '736',
          show_judge: 1,
          remark: '全局队列劵包弹出回调类型错误'
        })
      }
    }
  })
  window._GB_PopUpModalEventCenter_.emit('global-pop-modal-queue', { isWait: true, name, from: 'pushQueue' })
}

/**
 * 展示下一个全局弹窗
 * 上一个弹窗模块完成，调用下一个显示下一个弹窗模块
 * @param name 当前调用next方法的模块名称
 */
let hasAllDialogsFinished = false
window._GB_PopUpModalEventCenter_.next = (name) => {
  // 如果调用next的方法与正在展示的弹窗不一致，拦截
  if (name !== lastPopupModalName) {
    // 券包弹窗弹出前，上一个弹窗调用 next 方法名不是 coupon，则会被拦截掉
    // 不弹出券包弹窗
    if (name === 'coupon') {
      COUPON_MONITOR.errorMonitor({
        code: '740',
        show_judge: 1,
        remark: '上一个弹窗调用的next方法名与券包名不一致, 弹窗被拦截'
      })
    }
    return
  }
  // 更新上一个弹窗的状态
  if (!!lastPopupModalName) globPopupModalInfo[lastPopupModalName].isWait = false
  if (lastPopupModalName) {
    // 在关闭弹窗后触发埋点
    POP_UP_MONITOR.operationMonitor({
      event_name: 'expose_dq_dialog_close',
      dialog_name: lastPopupModalName,
      remark: `${lastPopupModalName} 弹窗关闭`
    })
  }
  const nextName = nextModalName()
  if(!hasAllDialogsFinished){
    POP_UP_MONITOR.operationMonitor({
      event_name: 'expose_dq_all_dialog_finish',
      dialog_queue: globDialogQueueNames.join(','), 
      remark: `弹窗完成所有请求数据` 
    })
    hasAllDialogsFinished = true
  }
  lastPopupModalName = nextName
  if(lastPopupModalName){
    Monitor.metricGlobPopup({
      tags: {
        track_type: '4', // dq_dialog_close
        alert_type: lastPopupModalName,
      },
      message: `${lastPopupModalName} dq_dialog_close`
    })
  }
  if(nextName && name === ''){
    // 上报弹窗队列开始弹窗的埋点
    POP_UP_MONITOR.operationMonitor({
      event_name: 'expose_dq_start',
      dialog_name: nextName, 
      dialog_queue: globDialogQueueNames.join(','),
      is_timeout: ENABLED_TIMEOUT_FLAG,
      timeout_dialog: NON_INIT_POPUP_STR,
      remark: '弹窗队列开始弹窗'
    })

  }
  setTimeout(() => { // 延后执行，不要阻塞正常的逻辑代码
    !!nextName && window._GB_PopUpModalEventCenter_.emit('global-pop-modal-show', nextName)
    if (nextName) {
      // 在此处添加埋点逻辑
      POP_UP_MONITOR.operationMonitor({
        event_name: 'expose_dq_dialog_real_show',
        dialog_name: nextName, // 正在显示的弹窗名称
        dialog_queue: globDialogQueueNames.join(','), // 当前队列中的弹窗名称列表
        remark: `${nextName} 弹窗弹出`
      })
      Monitor.metricGlobPopup({
        tags: {
          track_type: '3', // dq_dialog_real_show
          alert_type: nextName,
        },
        message: `${nextName} dq_dialog_real_show`
      })
    }
  }, 0)

  // TODO:temp 劵包弹窗前是否还有前置弹窗
  const globPopupArray = []
  for (let i = 0; i < globPopupModalSort.length; i++) {
    const key = globPopupModalSort[i]
    if (globPopupModalInfo[key].isWait) {
      globPopupArray.push(key)
    }
    if (key === 'coupon') {
      break
    }
  }
  
  if (globPopupArray.length > 1 && globPopupArray.includes('coupon')) {
    COUPON_MONITOR.operationMonitor({
      code: '764',
      show_judge: 1,
      remark: `存在优先级更高的营销活动 - ${globPopupArray?.toString()?.split('.')}`,
    })
  }
}

/**
 * 加载完成，但是不需要展示全局队列
 */
window._GB_PopUpModalEventCenter_.done = (name) => {
  if (!globPopupModalSort.includes(name)) return
  window._GB_PopUpModalEventCenter_.emit('global-pop-modal-queue', { isWait: false, name, from: 'done' })
}

function nextModalName() {
  for (let i = 0; i < globPopupModalSort.length; i++) {
    const key = globPopupModalSort[i]
    if (!!globPopupModalInfo[key].isWait) {
      return key
    }
  }
  return null
}

function isShowMutipleLangPop() {
  const supportMutipleLangModal = ['ma']
  if (!supportMutipleLangModal.includes(SiteUID)) return false
  if (getLocalStorage(`no_mutiple_lang_${SiteUID}_pop`)) return false
  return true
}

function isShowSwitchlanguageGuide() {
  const isCurrentCookieId = getLocalStorage('SWITCH_LANGUAGE_GUIDE_ID') === getCookie('cookieId')
  return !IS_RW && ['il', 'ilen', 'ca', 'cafr', 'se', 'sesv', 'th', 'then', 'eur', 'eurfr', 'eurde', 'eurnl', 'eurpt-pt', 'ma', 'maar', 'us', 'uses', 'euqs', 'euqscs-cz', 'euqsel-gr', 'euqsfr', 'euqsnl', 'euqsro'].includes(lang) && !isCurrentCookieId
}

/**
 * @description 是否弹出停止支持IE弹窗
 * @router 首页，列表，商详
 * @client IE
 * @return {Boolean} 控制弹窗是否显示
 */
function isShowStopSupportIEPop() {
  // 判断是否是ie浏览器
  if (!isOnlyIE()) return false
  const closeIEPopCookie = sessionStorage.getItem('closeStopSupportIEPop') || ''
  if (closeIEPopCookie) return false
  const page_name = window.getSaPageInfo?.page_name || window.SaPageInfo?.page_name || ''
  const supportPage = ['page_home', 'page_goods_detail', 'page_category', 'page_daily_new', 'page_real_class', 'page_select_class', 'page_search', 'page_goods_group']
  let flag = false
  for (let i = 0; i < supportPage.length; i++) {
    if (supportPage[i] == page_name) {
      flag = true
    }
  }
  return flag
}

/**
 * @description 优惠券包模块引入
 * @date 2021-10-14 10:38
 * @returns {Boolean}
 */
async function isShouldImportCoupon() {
  const pathname = location.pathname

  // 列表页匹配
  const matchList = [
    /.+-c-(\d+)\.html$/, // 实体分类
    /((\w+)\/)?.+-sc-([A-Za-z0-9]+)\.html$/, // 选品分类
    /pdsearch\/([^\/]+)\/?$/  //搜索页不展示券包
  ]

  const { CouponbagUpdate, SearchListCoupon, SearchSceneCoupon } = await getUserAbtData()
  
  // CouponbagUpdate 区分是否走弹出优化逻辑
  if (CouponbagUpdate?.param?.CouponbagUpdate === 'A') {
    // 列表匹配 - 列表品类劵跟全局劵包互斥
    if (!!matchList.find(v => v.test(pathname))) {
      // SearchListCoupon、 SearchSceneCoupon 区分是否有列表品类劵
      if ((!isLogin && SearchListCoupon?.param?.pos) || isLogin && SearchSceneCoupon?.param?.SearchSceneCouponPos) {
        COUPON_MONITOR.operationMonitor({
          code: '765',
          show_judge: 0,
          remark: '列表/搜索结果页命中ABT参数SearchListCoupon或SearchSceneCoupon',
        })
        return false
      }
    }
    return true
  } else {
    if (!!matchList.find(v => v.test(pathname))) {
      // 显示列表券不显示券包弹窗
      const pc_list_coupon = window.gbRawData?.results?.cccListOperation?.pc_list_coupon ?? {}
      const showType = pc_list_coupon?.showType
      const cateCoupon = pc_list_coupon?.cateCoupon ?? []

      const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'popup_modal' }) ?? ''
      if (showType && filterCouponWithin24Hours(`selectListDisplayedCoupon_${member_id}`, cateCoupon, 15000, 1)?.length > 0) {
        COUPON_MONITOR.operationMonitor({
          code: '765',
          show_judge: 1,
          remark: '列表/搜索结果页命中ABT参数SearchListCoupon或SearchSceneCoupon',
        })
        return false
      }
    }

    // 店铺页不展示券包
    if (/store\/home$/.test(pathname)) {
      COUPON_MONITOR.operationMonitor({
        code: '765',
        show_judge: 0,
        remark: '店铺页不展示券包',
      })
      return false
    }
    return true
  }
}

/**
 * @description 过滤不需要弹窗的页面
 * @returns {Boolean}
 */
function isCloseAllPop ()  {
  const pages = ['transit', 'email_unbind', 'credentials', '/risk/challenge', '/iframe_login']
  const fhost = [
    'https://ccc-test01.biz.sheincorp.cn', 'https://ccc-test02.biz.sheincorp.cn', 'https://ccc-store-test01.biz.sheincorp.cn', 'https://ccc-store-test02.biz.sheincorp.cn',
    'https://ccc-gray01.biz.sheincorp.cn', 'https://ccc-gray02.biz.sheincorp.cn', 'https://ccc-store-gray01.shein.com', 'https://ccc-store-gray02.shein.com',
    'https://ccc.biz.sheincorp.cn', 'https://ccc-store.shein.com'
  ]
  let flag = false
  pages.forEach(item => {
    if (location.pathname.indexOf(item) > -1) {
      flag = true
    }
  })
  let url = ''
  if (parent !== window) {
    try {
      url = parent.location.href
    } catch (error) {
      url = document.referrer
    }
  }
  if (fhost.some(val => url.indexOf(val) != -1)) {
    flag = true
  }
  return flag
}

/**
 * 超时机制处理
 * 根据abt配置，配置了超时时间则在超时时间内未初始化完成的弹窗，手动初始化done
 * 目的：不会因为一个弹窗的无限超时导致其他弹窗无法正常弹窗，弹窗队列无法启动
 */
function handleTimeoutFn ({ abt = {} }) {
  const { HomePopup } = abt
  const timeoutVal = Number(HomePopup?.p?.queue_timeout)
  // const timeoutVal = 5
  if(!HomePopup?.p?.queue_timeout || isNaN(timeoutVal) || timeoutVal <= 0) return
  const timerId = setTimeout(() => {
    ENABLED_TIMEOUT_FLAG = 1
    // 开始进入超时逻辑
    // 获取所有还未初始化完成弹窗，标识符为loading=true
    const nonInitPopupList = Object.keys(window._GB_PopUpModalEventCenter_?.globPopupModalInfo || {}).filter(modalName => {
      return window._GB_PopUpModalEventCenter_.globPopupModalInfo[modalName].loading === true
    })
    NON_INIT_POPUP_STR = nonInitPopupList.join(',')
    POP_UP_MONITOR.normaMonitor({
      nonQueue: true, // 非队列埋点，正常上报，不依据abt来控制上报不上报
      timeout_dialog: NON_INIT_POPUP_STR,
      remark: '弹窗队列超时启动',
      event_name: 'expose_dq_timeout'
    })
    if(nonInitPopupList?.length > 0) {
      nonInitPopupList.forEach(modalName => {
        window._GB_PopUpModalEventCenter_.done(modalName)
      })
    }
  }, timeoutVal * 1000)
}
// 初始化全局弹窗
export async function loadGlobalPopModule() {
  // 不要轻易改位置
  startGlobPopupTime = new Date().getTime()
  POP_UP_MONITOR.operationMonitor({
    remark: '弹窗队列初始化完成，准备启动',
    event_name: 'expose_dq_launch'
  })
  COUPON_MONITOR.normaMonitor({
    code: '708',
    remark: '弹窗队列开始初始化',
  })

  // 强制改密弹窗
  import(/* webpackChunkName: "forceUpdatePwd-module" */ './forceUpdatePwd').then(() => { })

  // webpush弹窗
  import(/* webpackChunkName: "sheinPush-module" */'./sheinPush').then(() => { })
  
  if (isCloseAllPop()) {
    COUPON_MONITOR.operationMonitor({
      code: '765',
      show_judge: 0,
      remark: '命中isCloseAllPop为true',
    })
    return
  }
  // 取abt数据
  const { HomePopup } = await getUserAbtData()
  handleTimeoutFn({
    abt: { HomePopup },
  })

  /**
   *  IP跳转弹窗引入
   */
  if (!['hk'].includes(SiteUID)) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'ip',
      remark: 'IP跳转弹窗启动',
      event_name: 'expose_dq_ip_start'
    })
    import(/* webpackChunkName: "ipPopUp-module" */
      './ipPopUp').then(() => { })
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'ip',
      remark: 'IP跳转弹窗未启动',
      event_name: 'expose_dq_ip_end'
    })
    window._GB_PopUpModalEventCenter_.done('ip')
  }

  /**
   *	语言选择弹窗
   */
  if (isShowMutipleLangPop()) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'mutipleLang',
      remark: '语言选择弹窗启动',
      event_name: 'expose_dq_mutipleLang_start'
    })
    import(/* webpackChunkName: "mutilple-lang-module" */'./switchLanguage').then(() => { })
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'mutipleLang',
      remark: '语言选择弹窗未启动',
      event_name: 'expose_dq_mutipleLang_end'
    })
    globPopupModalInfo['mutipleLang'] && (globPopupModalInfo['mutipleLang'].loading = false)
  }

  /**
   * 停止支持IE弹窗
   */
  if (isShowStopSupportIEPop()) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'stopSupportIE',
      remark: '停止支持IE弹窗启动',
      event_name: 'expose_dq_stopSupportIE_start'
    })
    import(
      /* webpackChunkName: "stopSupportIEPop-module" */
      './stopSupportIEPop'
    ).then(() => { })
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'stopSupportIE',
      remark: '停止支持IE弹窗启动',
      event_name: 'expose_dq_stopSupportIE_end'
    })
    globPopupModalInfo['stopSupportIE'] && (globPopupModalInfo['stopSupportIE'].loading = false)
  }

  /**
   * 优惠券包模块引入
   */
  // [TODO：1]
  isShouldImportCoupon().then(res => {
    if (res) {
      POP_UP_MONITOR.operationMonitor({
        dialog_name: 'isShould',
        remark: 'isShould弹窗启动',
        event_name: 'expose_dq_isShould_start'
      })
      COUPON_MONITOR.normaMonitor({
        code: '709',
        show_judge: 1,
        remark: '开始加载券包弹窗资源'
      })
      import(/* webpackChunkName: "coupon-module" */ './coupon_package')
        .then(() => {
          COUPON_MONITOR.normaMonitor({
            code: '710',
            show_judge: 1,
            remark: '券包弹窗资源加载成功'
          })
        })
        .catch(() => {
          window._GB_PopUpModalEventCenter_.done('coupon')
          COUPON_MONITOR.errorMonitor({
            code: '741',
            show_judge: 1,
            remark: '券包弹窗资源加载失败'
          })
        })
    } else {
      POP_UP_MONITOR.operationMonitor({
        dialog_name: 'isShould',
        remark: 'isShould弹窗未启动',
        event_name: 'expose_dq_isShould_end'
      })
      globPopupModalInfo['coupon'] && (globPopupModalInfo['coupon'].loading = false)
    }
  })

  /**
    * 用劵提醒弹窗引入
    */
  function isShouldImportCouponRevisit () {
    return isLogin && !IS_RW
  }
  if (isShouldImportCouponRevisit()) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'couponRevisit',
      remark: '用劵提醒弹窗启动',
      event_name: 'expose_dq_couponRevisit_start'
    })
    import(/* webpackChunkName: "coupon-revisit-module" */
      './coupon_revisit_package').then(() => { })
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'couponRevisit',
      remark: '用劵提醒弹窗未启动',
      event_name: 'expose_dq_couponRevisit_end'
    })
    globPopupModalInfo['couponRevisit'] && (globPopupModalInfo['couponRevisit'].loading = false)
  }

  /**
   * 快速注册弹窗引入
   */
  // 非登陆并且非用户子页面和购物车
  if (!isLogin && !/\/user\/.+$|\/cart$/.test(location.pathname) && !/\/auth\/login$/.test(location.pathname) && !window.noNeedQuickRegister) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'quickRegister',
      remark: '快速注册弹窗启动',
      event_name: 'expose_dq_quick_register_start'
    })
    import(/* webpackChunkName: "quick-register-module" */
      './quick_register').then(() => { })
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'quickRegister',
      remark: '快速注册弹窗未启动',
      event_name: 'expose_dq_quick_register_end'
    })
    globPopupModalInfo['quickRegister'] && (globPopupModalInfo['quickRegister'].loading = false)
  }

  /**
   * 
   * 切换多语言引导
   * 
   * */
  if (isShowSwitchlanguageGuide()) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'languageGuide',
      remark: '切换多语言引导启动',
      event_name: 'expose_dq_language_guide_start'
    })
    if(window.requestIdleCallback){
      window.requestIdleCallback(() => {
        import(/* webpackChunkName: "switch-language-guide-module" */ './switchLanguageGuide')
      })
    } else {
      import(/* webpackChunkName: "switch-language-guide-module" */ './switchLanguageGuide')
    }
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'languageGuide',
      remark: '切换多语言引导未启动',
      event_name: 'expose_dq_language_guide_end'
    })
    globPopupModalInfo['switchLanguageGuide'] && (globPopupModalInfo['switchLanguageGuide'].loading = false)
  }

  function isImportMarketingPop() {
    const href = location.href
    const pathname = location.pathname

    // 参数限制
    if (href.indexOf('utm_campaign=GM_Share') == -1) return false
    // 开放的路由
    const openRoutes = [ // 必须是正则模式
      /\/.+-p-(\d+)(-cat-(\d+))?\.html/, // 商详
      /\/api\/productInfo\/quickView\/get/, // 商详
      /\/(rw-)?invite$/, // 活动邀请页
    ]
    return openRoutes.some((v) => v.test(pathname))
  }
  if (getLocalStorage('marketing_block_pop') != 1 && isImportMarketingPop()) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'marketing',
      remark: 'marketing弹窗启动',
      event_name: 'expose_dq_marketing_popup_start'
    })
    import(/* webpackChunkName: "marketing_pop_module" */ './marketing/')
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'marketing',
      remark: 'marketing弹窗未启动',
      event_name: 'expose_dq_marketing_popup_end'
    })
    window._GB_PopUpModalEventCenter_.done('marketing')
  }

  // 多主体隐私政策弹窗
  function isImportMutiplePartModule() {
    const pathname = location.pathname
    const ids = [282, 488, 399] // 隐私文章页不弹窗
    if (ids.some((v) => pathname.indexOf(`-a-${v}.html`) > -1)) {
      return false
    }
    return isLoginFn() && window?.sessionStorage?.getItem('mutiplePartPrivacy') != 1
  }
  if (isImportMutiplePartModule()) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'mutiplePartPrivacy',
      remark: '多主体隐私政策弹窗启动',
      event_name: 'expose_dq_mutiplePart_start'
    })
    import(/* webpackChunkName: "mutiplePartPrivacy-module" */'./mutiplePartPrivacy')
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'mutiplePartPrivacy',
      remark: '多主体隐私政策弹窗未启动',
      event_name: 'expose_dq_mutiplePart_end'
    })
    window._GB_PopUpModalEventCenter_.done('mutiplePartPrivacy')
  }

  /**
    * sheinPush弹窗引入
    */
  function isShouldImportSheinPush () {
    // 由于加载时间的不确定, ab不做了
    // // 据marketing调整push弹窗的位置
    // const webPushOrderBeforMarketing = HomePopup?.param?.pushPopupSort_pc == '0'
    // let onetrustIdx = -1 
    // let marketingIdx = -1
    // // update popup info
    // for (let i = 0; i < globPopupModalSort.length; i++) {
    //   const key = globPopupModalSort[i]
    //   if(key === 'onetrust') {
    //     onetrustIdx = i
    //   }
    //   if(key === MARKETING) {
    //     marketingIdx = i
    //   }
    // }

    // if(onetrustIdx < marketingIdx) {
    //   if(webPushOrderBeforMarketing) {
    //     // marketing前面插入
    //     globPopupModalSort.splice(marketingIdx, 0, SHEIN_PUSH)
    //   } else {
    //     // marketing后面插入
    //     globPopupModalSort.splice(marketingIdx + 1, 0, SHEIN_PUSH)
    //   }
    // } else {
    //   const webPushOrderAfterOnetrust = webPushOrderBeforMarketing
    //   if(webPushOrderAfterOnetrust) {
    //     // onetrust后面插入
    //     globPopupModalSort.splice(onetrustIdx + 1, 0, SHEIN_PUSH)
    //   } else {
    //     // 最后一个插入
    //     globPopupModalSort.push(SHEIN_PUSH)
    //   }
    // }


    // 最后一个插入
    globPopupModalSort.push(SHEIN_PUSH)

    // init shein web push
    globPopupModalInfo[SHEIN_PUSH] = { loading: false, isWait: false }


    const supportPage = ['page_home', 'page_goods_detail', 'page_category', 'page_daily_new', 'page_real_class', 'page_select_class', 'page_search', 'page_goods_group']

    let shouldImport = true

    const page_name = window.getSaPageInfo?.page_name || window.SaPageInfo?.page_name || ''

    if (!supportPage.includes(page_name)) {
      shouldImport = false
    }
    return shouldImport 
  }


  // 小于 24小时
  const ONE_DAY_MS = 1000 * 60 * 60 * 24
  const isWithinLastDay = (timestamp) => !!timestamp && (Date.now() - timestamp < ONE_DAY_MS)

  const webpushShowLastTime = getLocalStorage(`webpushShowLastTime`) || null

  if(isShouldImportSheinPush() && !isWithinLastDay(webpushShowLastTime) ) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: SHEIN_PUSH,
      remark: 'sheinPush弹窗启动',
      event_name: 'expose_dq_web_push_start'
    })
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: SHEIN_PUSH,
      remark: 'sheinPush弹窗未启动',
      event_name: 'expose_dq_web_push_end'
    })
    isSheinPushDone = true
    globPopupModalInfo[SHEIN_PUSH] && (globPopupModalInfo[SHEIN_PUSH].loading = false)
    window._GB_PopUpModalEventCenter_.done(SHEIN_PUSH)
  }
  
  // isImportMutiplePartModule() ?
  //   import(/* webpackChunkName: "mutiplePartPrivacy-module" */'./mutiplePartPrivacy') :
  //   window._GB_PopUpModalEventCenter_.done('mutiplePartPrivacy')
}
