import { getCookie } from '@shein/common-function'
import apiReport from 'public/src/pages/common/apiReport'
const { SiteUID } = gbCommonInfo
export class Riskified {
    static name = 'Riskified'
    #RiskSessionId = ''
    #StoreDomain = ''

    _getRiskSessionId() {
      if (this.#RiskSessionId) {
        return { datas: this.#RiskSessionId }
      }
      this.#RiskSessionId = getCookie('cookieId') //Math.random().toString().substring(2, 10) + Date.now()
      this.#StoreDomain = 'www.shein.com'
      let url = '//beacon.riskified.com?shop=' + this.#StoreDomain + '&sid=' + this.#RiskSessionId
      return {
        datas: this.#RiskSessionId,
        pixels: [{
          TPM_Ele_Type: 'script',
          src: url,
          onload: () => {
            window.isRiskifiedLoaded = true
          },
          onerror: () =>{
            apiReport.report({
              apiPath: 'riskified/before-payment/error',
              siteuid: SiteUID,
              scene: 'js sdk load fail',
              riskSessionId: this.#RiskSessionId
            })
          }
        }]
      }
    }
}
