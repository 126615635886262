import { onWindowLoad } from 'public/src/pages/common/utils/index.js'
// 注: TODO 所需的异步资源可以提前预取
onWindowLoad(function () {
  requestAnimationFrame(() => {
    if (document.querySelectorAll('.j-black-mask').length) {
      import(/* webpackChunkName: "LoadTipsContainer" */ './index').then(({ default: LoadTipsContainer }) => {
        new LoadTipsContainer().init()
      })
    }
  })
})
