export function isIE () {
  if(!!window.ActiveXObject || 'ActiveXObject' in window || window.navigator.userAgent.indexOf('Edg') > -1) {
    return true
  } else {
    return false
  }
}
export function isOnlyIE () {
  if(!!window.ActiveXObject || 'ActiveXObject' in window) {
    return true
  } else {
    return false
  }
}
