'use strict'
import { getQueryString as getQueryStringFn, getCookie, setCookie, htmlDecode as htmlDecodeFn, transformImg as transformImgFn } from '@shein/common-function'
import { isMobile, getGoodsUrl, isLogin, queryString, uniteJump } from 'public/src/pages/common/utils/index.js'

// SHEIN_W 需要回归业务 (目前剩余的这一部分都是 html 模版中会直接用到的方法, 其余部分均已回归业务)
export const SHEIN_W = {
  getGoodsUrl,
  getQueryString: (key) => {
    return getQueryStringFn({ key })
  },
  isLogin,
  isMobile,
  getCookie,
  setCookie,
  queryString,
  htmlDecode: function(text) {
    return htmlDecodeFn({ text })
  },
}

// 图片格式转换
// 注: 目前html模版中有用到该方法
window.transformImg = function(img) {
  return transformImgFn({ img })
}

// 富文本、faq、robot再用
window.uniteJump = uniteJump

window.SHEIN_W = SHEIN_W
