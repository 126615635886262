const fetchIp = async (url, headerField, retryCount = 0) => {
  try {
    const response = await fetch(url)
    const result = response.headers.get(headerField)

    if (!result) return retry()

    return result
  } catch (error) {
    return retry()
  }

  async function retry() {
    if (retryCount < 1) {
      await new Promise(resolve => setTimeout(resolve, 5000))
      return fetchIp(url, headerField, retryCount + 1)
    }
    throw new Error('[IP_ADDRESS_REPORT]: Number of retries exceeded')
  }
}

const collectIpData = async () => {
  const [ipv4, ipv6] = await Promise.all([
    fetchIp('https://cinfo-v4.shein.com', 'client-ipaddr-v4'),
    fetchIp('https://cinfo-v6.shein.com', 'client-ipaddr-v6')
  ])
  // devices.js 在此之前初始化
  await window._GB_DeviceFingerPrint.callFuncPromise()
  const device_id =
    window._GB_DeviceFingerPrint.smDeviceId || window.SMSdk?.getDeviceId?.()

  return {
    'client-ipaddr-v4': ipv4,
    'client-ipaddr-v6': ipv6,
    timestamp: Date.now(),
    device_id
  }
}

const reportIpData = async data => {
  const { REPORT_URL } = gbCommonInfo
  await fetch(`${REPORT_URL.SA_REPORT_URL}/ip-report`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
}

const startCollection = async () => {
  try {
    const data = await collectIpData()
    await reportIpData(data)
  } catch (error) {
    // ignore
  }
}

if (typeof window !== 'undefined') {
  startCollection()
  setInterval(startCollection, 2 * 60 * 1000)
}
