// 注: TODO 所需的异步资源可以提前预取
export function loadUserDropDownContainer() {
  const userDropdownVueDom = document.querySelector('.j-user-dropdown-vue')
  if (userDropdownVueDom) {
    requestIdleCallback(() => {
      import(/* webpackChunkName: "UserDropDownContainer" */ './index').then(({ default: UserDropDownContainer }) => {
        new UserDropDownContainer().init()
      })  
    })
  }
}
