import mitt from 'mitt'
import { onWindowLoad } from 'public/src/pages/common/utils/index.js'

window.primeEventCenter = window.primeEventCenter || mitt()

// 尽在需要满足加载 Sidebar 资源时异步加载
// 注: TODO 所需的异步资源可以提前预取
onWindowLoad(() => {
  requestAnimationFrame(() => {
    const memeberSideBarDom = document.querySelector('.j-member-sidebar')
    if (typeof window.GB_MEMBER_SIDEBAR !== 'undefined' && memeberSideBarDom) {
      import(/* webpackChunkName: "sideBarContainer" */ './index').then(({ default: RenderSidebar }) => {
        new RenderSidebar().init()
      })
    }
  })
})
