/**
 * 全局隐私政策模块按需加载模块
 */
import mitt from 'mitt'
import { SMessage } from '@shein-aidc/sui-message'

const { SHEIN_KEY_PC_14541 } = gbCommonInfo && gbCommonInfo.language || {}
const evt = window._GB_EventCenter = mitt()

/**
 * 对外提供的方法,展示弹窗登录组件
 * @param {object} config
 * @param config.type 隐私政策类型 / p&c: 1 t%c: 2
 */
function showPop (data) {
  if (directOpenLink(data)) return
  if (typeof window._GB_GobalPrivayInstance === 'undefined') {
    initPageLoadingModal(data)
  } else {
    evt.emit('event-global-privay-page-component', data)
  }
}

function directOpenLink (data) {
  const { IS_RW, langPath } = gbCommonInfo
  const types = {
    privacy: { // 隐私政策
      id: IS_RW ? 488 : 282,
      link: '/Privacy-Security-Policy-a-{0}.html',
    },
    termsAndConditions: { // t &a 政策
      id: 399,
      link: '/Terms-and-Conditions-a-{0}.html',
    },
    privacyCenter: {
      id: 1045,
      link: '/Privacy-Center-a-{0}.html',
    }
  }
  const { type } = data
  const { id, link } = types[type] || {}
  if (!id) return false
  if (!link) return false
    
  window.open(langPath + link.replace('{0}', id), '_blank')
  return true

}

function createPrivayHhtml () {
  const loadingHtml = document.createElement('div') // loading HTML
  loadingHtml.setAttribute('class', 'j-global-privacy-loading')
  loadingHtml.innerHTML = '<modal-vue :show="isShowLoading" :loading="true"></modal-vue>'

  const mainHtml = document.createElement('div') // 登录弹窗HTML
  mainHtml.setAttribute('class', 'j-global-privacy')

  return { mainHtml, loadingHtml }
}

// 初始化登录模块
function initPageLoadingModal (data) {
  const el = createPrivayHhtml()

  const fragment = document.createDocumentFragment()

  fragment.appendChild(el.loadingHtml)
  fragment.appendChild(el.mainHtml)

  document.body.appendChild(fragment)
  SMessage({ type: 'loading', message: `${SHEIN_KEY_PC_14541}...`, duration: 0  })
  import(/* webpackChunkName: "global-privay-module" */ './index').then(() => {
    evt.isLoadingJsModule = false
    setTimeout(() => {
      evt.emit('event-global-privay-page-component', data)
      SMessage.closeAll()
    }, 0)
  })
}

window.SHEIN_GLOBAL_PRIVACY = {
  show: showPop
}
