import { isArray, isFunction, isPromise } from '@shein/common-function'

export function nextIdle() {
  return new Promise(resolve => {
    requestIdleCallback(() => resolve())
  })
}

export function isArrayPixel(obj) {
  return isArray(obj) && obj.length && obj.every(_ => _.TPM_Ele_Type)
}

export function isPixel(obj) {
  return isArrayPixel(obj) || isPromise(obj) || isFunction(obj)
}
