const saModule = {
  click({ name, param = '{}', beacon }) {
    try {
      param = JSON.parse(param)
    } catch (error) {
      console.log(error)
      param = {}
    }
    sa('send', {
      activity_name: 'click_' + name,
      activity_param: param
    }, { beacon: beacon ? 1 : 0 })
  },
  view({ name, param = '{}', beacon }) {
    try {
      param = JSON.parse(param)
    } catch (error) {
      console.log(error)
      param = {}
    }
    sa('send', {
      activity_name: 'expose_' + name,
      activity_param: param
    }, { beacon: beacon ? 1 : 0 })
  }
}

export default saModule
