import * as Business from './business'
const { templateType, SiteUID } = gbCommonInfo

// 预链接配置
export function getOrigins() {
  let originsList = [
    '//s3.amazonaws.com'
  ]
  SiteUID === 'us' && originsList.push(
    '//amplify.outbrain.com',
    '//snap.licdn.com',
    '//ads.nextdoor.com'
  )
  SiteUID === 'ru' && originsList.push('//mc.yandex.ru')
  SiteUID === 'jp' && originsList.push(
    '//cdn.smartnews-ads.com',
    '//s.yimg.jp'
  )
  SiteUID === 'asia' && originsList.push('//wcs.naver.net')
  SiteUID === 'mx' && originsList.push('//dtm-dre.platform.hicloud.com')
  if (templateType === 'paySuccess') {
    SiteUID === 'us' && originsList.push('//s.amazon-adsystem.com')
  }
  if (['us', 'fr', 'rwus'].includes(SiteUID)) originsList.push('//sc-static.net')

  return originsList
}

export function getBusinessSources() {
  return Business || {}
}

export async function getSources(OL_MODE) {
  return OL_MODE
    ? Object.assign({}, ...await Promise.all([
      import(/* webpackChunkName: "tpm-sources-globals" */'./globals'),
      templateType === 'paySuccess' ? import(/* webpackChunkName: "tpm-sources-pay_success" */'./pay_success') : {}
    ]))
    : {} //import(/* webpackChunkName: "tpm-sources-whitelist" */'./whitelist')
}
