import apiReport from 'public/src/pages/common/apiReport'
import schttp from 'public/src/services/schttp'
const { PUBLIC_CDN, NODE_SERVER_ENV, SiteUID, SERVER_TYPE } = gbCommonInfo
const isProd = !['localhost', 'debug'].includes(NODE_SERVER_ENV)
export class Forter {
  static name = 'Forter'

  #Fort_LOAD = false
  apolloConfig = null

  _getApollo() {
    if (this.apolloConfig) return this.apolloConfig
    this.apolloConfig = schttp({
      method: 'POST',
      url: '/api/config/apollo/get',
      data: {
        apolloKeys: 'FORTER_NOT_US'
      }
    })
      .then(res => {
        //FORTER_NOT_US为1时，us和非us都是同一个siteId，为0时区分美国和非美
        //默认灰度 & 生产的非美值 cc376223223c
        const FORTER_NOT_US = res?.info?.FORTER_NOT_US
        let forterSiteId = isProd ? 'cc376223223c' : '9dad99c93b0e'
        if (FORTER_NOT_US == 1) {
          forterSiteId = isProd ? 'fdfe4ed62699' : 'f3cf410d78da'
        } else {
          if (isProd) {
            //灰度&生产的美国
            if (SERVER_TYPE == 'usa') {
              forterSiteId = 'ffb9ca7033a3'
            }
          } else {
            //测试环境目前没分美国和非美服务器，先用siteuid来判断
            if (['us', 'rwus'].includes(SiteUID)) {
              forterSiteId = 'b044e212c01e'
            }
          }
        }
        return forterSiteId
      })
      .catch(() => 'cc376223223c')
    return this.apolloConfig
  }

  async _loadFort() {
    if (this.#Fort_LOAD) return
    window.forterSiteId = await this._getApollo()
    return {
      pixels: [{
        TPM_Ele_Type: 'script',
        TPM_Config: {
          CDN: true
        },
        id: window.forterSiteId,
        src: `${PUBLIC_CDN}/she_dist/libs/TPM/${isProd ? 'forter_prod_ver' : 'forter_dev_ver'}-2.31.js`,
        onload: () => {
          this.#Fort_LOAD = true
          window.forterSDKLoaded = true
        },
        onerror: () => {
          apiReport.report({
            apiPath: 'forter/before-payment/error',
            siteuid: SiteUID,
            scene: 'js sdk init fail'
          })
        }
      }]
    }
  }
}
