if ('AbortController' in window) {
  window.SchttpAbortCon = AbortController
} else {
  class AbortSignal {
    _aborted = false
    onCanceled = null
    get aborted() {
      return this._aborted
    }
    addEventListener(event, onCanceled) {
      this.onCanceled = onCanceled
    }
    removeEventListener() {
      this.onCanceled = null
    }
    dispatchEvent(e) {
      this._aborted = true
      this.onCanceled?.(e)
    }
  }
  class AbortController {
    signal = new AbortSignal()
    abort(e) {
      this.signal.dispatchEvent(e)
    }
  }
  window.SchttpAbortCon = AbortController
}
