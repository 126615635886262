const { gitInfo = {}, NODE_SERVER_ENV = '', SiteUID = '', WEB_CLIENT } = gbCommonInfo
import schttp from 'public/src/services/schttp'

export function istanbulAnalytics() {
  if(window.__coverage__ && gitInfo.branch){
    try{
      const jsonFile = new File([JSON.stringify(window.__coverage__)], 'coverage.json', { type: 'text/json' })
      const formData = new FormData()
      formData.append('coverage', jsonFile)
      formData.append('branchName', gitInfo.branch || '') // 分支名称
      formData.append('gitCommitId', gitInfo?.commitId || '') // 打包时的commit id(完整的id，非短名称)
      formData.append('env', NODE_SERVER_ENV) // 当前的环境
      formData.append('platform', 'pc')
      formData.append('site', SiteUID)
      schttp({
        url: 'https://tep.sheincorp.cn/api/coverage/upload/web',
        method: 'POST',
        data: formData,
        headers: {
          brand: WEB_CLIENT
        },
        timeout: 60000,
      }).then(() => {
      })
    }catch(e){
      console.log('send coverage fail')
      console.log(e)
    }
  }
  // schttp({
  //   url: '/other/coverage/create',
  // }).then(() => {
  // })
}
