import { daEventCenter } from 'public/src/services/eventCenter'
import { abtUserAnalysis } from '@shein-aidc/basis-abt-router'
import { getLocalStorage } from '@shein/common-function'
import { queryString, onWindowLoad } from 'public/src/pages/common/utils/index.js'
import { delegate } from './utils'

daEventCenter.addSubscriber({ modulecode: '1-22-2' })
daEventCenter.addSubscriber({ modulecode: '2-16' })

import { repPointState } from 'public/src/pages/common/biz_helper/wish_redpoint'
const { IS_RW } = gbCommonInfo
// TODO
const channel_type = getLocalStorage('cate_channel_type')
const postkeyObj = {
  '2': WEB_CLIENT == 'shein' ? '' : 'CccGirlsCategory',
  '3': WEB_CLIENT == 'shein' ? 'CccMenCategory' : 'CccGuysCategory',
  '4': 'CccKidsCategory',
  '5': 'CccHomeCategory',
  '6': 'CccPlussizeCategory',
  '7': 'CccSwimwearCategory',
  '8': 'CccSaleCategory',
  '9': 'CccBeautyHomepage'
}
const englishChannelName = IS_RW ? {
  2: 'Girls',
  3: 'Guys',
  4: 'Kids',
  5: 'Home',
  6: 'Plussize',
  7: 'Swimwear',
  8: 'Sale',
  9: 'Beauty',
} : {
  2: 'Women',
  3: 'Men',
  4: 'Kids',
  5: 'Home',
  6: 'Plussize',
  7: 'Swimwear',
  8: 'Sale',
  9: 'Beauty',
}
const currentPostKey = postkeyObj[channel_type]

// navigation preview
let navigationPreview = false
const { content_id, scene_key, contentId, channelType, sceneKey } = queryString.parse(location.search)
if ((content_id && scene_key && scene_key.includes('navigation')) || (contentId && channelType && sceneKey && sceneKey.includes('navigation'))) {
  navigationPreview = true
}

window.S_TopBannerGA = {
  //加载topbanner完才触发
  ABTInfo: abtUserAnalysis({ posKeys: currentPostKey }),
  /**
   * 顶部栏自有埋点上报
   */
  exposeRedPointSa() {
    const abtInfo = abtUserAnalysis({ posKeys: currentPostKey }).sa
    const param = document.querySelector('.j-fast-wish span')?.textContent.trim()

    sa('send', {
      activity_name: 'expose_top_site_collect',
      activity_param: {
        collect_count: param,
        is_red: repPointState.redpoint ? 1 : 0,
        abtest: abtInfo
      },
    })
  }
}

onWindowLoad(function() {
  let ABTInfo = abtUserAnalysis({ posKeys: currentPostKey })

  delegate(document, 'click', '.header-nav .j-change-currency', (e) => {
    const currency = e.target.dataset['currency']
    sa('send', {
      activity_name: 'top_site_subsite_money',
      activity_param: {
        money_type: currency,
        abtest: ABTInfo.sa
      }
    }, { beacon: 1 })
  })

  delegate(document, 'click', '.header-nav .j-free-shipping a', (e) => {
    const href = e.target.getAttribute('href')
    sa('send', {
      activity_name: 'click_top_site_activity',
      activity_param: {
        activity_content: href,
      }
    }, { beacon: 1 })
  })

  delegate(document, 'click', '.j-header-username-icon', (e) => {
    const url = e.target.getAttribute('href')
    const text = url.substring(url.lastIndexOf('/') + 1)
    sa('send', {
      activity_name: 'click_top_site_account',
      activity_param: {
        account_content: '0' + '`' + text,
        abtest: ABTInfo.sa
      }
    }, { beacon: 1 })
  })

  delegate(document, 'click', '.j-fast-wish', (e) => {
    const param = e.target.querySelector('span')?.textContent.trim()
    sa('send', {
      activity_name: 'click_top_site_collect',
      activity_param: {
        collect_count: param,
        is_red: repPointState.redpoint ? 1 : 0
      },
    }, { beacon: 1 })
  })

  delegate(document, 'click', '.j-fast-cart .icon-bag, .j-sa-cart-1', e => {
    const bag_goods_count = e.target.classList.contains('.j-sa-cart-1') ?
      e.target.querySelector('span')?.textContent.trim() :
      e.target.textContent.trim()

    daEventCenter && daEventCenter.triggerNotice({
      daId: '2-16-5',
      extraData: {
        bag_goods_count,
        abtest: ABTInfo.sa
      }
    })
  })

  delegate(document, 'click', '.header-nav .view-bag', () => {
    const param = document.querySelectorAll('.j-sa-cart-1').length > 0 ? document.querySelector('.j-sa-cart-1 span')?.textContent.trim() :
      document.querySelector('.j-fast-cart .cart-num')?.textContent.trim()
    sa('send', {
      activity_name: 'click_top_site_bag',
      activity_param: {
        bag_goods_count: param,
        type: 2,
        abtest: ABTInfo.sa
      }
    }, { beacon: 1 })
  })

  delegate(document, 'click', '.j-nav-first-cate', (e) => {
    if (navigationPreview) return
    // 这里需要立即拿到更新的ABTInfo
    const channel_type = getLocalStorage('cate_channel_type')
    const currentPostKey = postkeyObj[channel_type]
    ABTInfo = abtUserAnalysis({ posKeys: currentPostKey })
    const pitPos = Number(e.target.dataset['index']) + 1
    const url = e.target.getAttribute('href')
    sa('send', {
      activity_name: 'click_navigation_menu',
      activity_param: {
        first_menu_hole: pitPos,
        first_menu_content: englishChannelName[channel_type],
        first_content_list: url,
        second_menu_hole: '',
        second_menu_content: '',
        third_menu_hole: '',
        third_menu_content: '',
        fourth_menu_hole: '',
        fourth_menu_content: '',
        abtest: ABTInfo.sa,
        src_module: 'topcat',
        src_identifier: 'fc=' + englishChannelName[channel_type] + '`sc=0`tc=0`oc=0`ps=tab0' + pitPos + '`jc=0'
      }
    }, { beacon: 1 })
  })

  delegate(document, 'click', '.ft-left-list a', (e) => {
    const target = e.target
    const index = Number(target.dataset['index']) + 1
    const subIndex = Number(target.dataset['subindex']) + 1
    const titleGroup = target.dataset['titlegroup']
    const title = target.dataset['title']
    const url = target.dataset['url']
    sa('send', { activity_name: 'click_public_bottom', activity_param: {
      index: index + '_' + titleGroup + '`' + subIndex + '_' + title,
      url: url
    } })
  })
})
